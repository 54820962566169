import { useState} from 'react';
import Carousel from 'react-grid-carousel';

import {FiArrowLeft,FiArrowRight } from 'react-icons/fi';
import ImageCard from './imageCard';
import { imageList } from '../helpers/imageGatherer';

function LogoList({images}){

    const [isLeftHover, setIsLeftHover] = useState(false);
    const [isRightHover, setIsRightHover] = useState(false);

    const leftArrow = () => {
        return(
            <div className='testArrowHolder'>
                <div className='testArrowLeft' onMouseEnter={() => setIsLeftHover(true)} onMouseLeave={() => setIsLeftHover(false)}>
                    <FiArrowLeft size={60} color={[isLeftHover ? "#3eacd1" : "#562873"]} />
                </div>
            </div>
        );
    }
    const rightArrow = () => {
        return(
            <div className='testArrowHolder'>
                <div className='testArrowRight' onMouseEnter={() => setIsRightHover(true)} onMouseLeave={() => setIsRightHover(false)}>
                    <FiArrowRight size={60} color={[isRightHover ? "#3eacd1" : "#562873"]} />
                </div>
            </div>
        );
    }
    const MyDot = ({ isActive }) => (
        <span
          style={{
            display: 'inline-block',
            height: isActive ? '20px' : '10px',
            width: isActive ? '20px' : '10px',
            background: isActive ? '#52D477' : "#562873",
            borderRadius: "35px"
          }}
        ></span>
      )
    return(
        <>
                <div className='testMain'>
                                <Carousel autoplay={2000} cols={1} rows={1} loop showDots={true} dot={MyDot} arrowLeft={leftArrow} arrowRight={rightArrow}>
                                    {imageList.map((real, index) => {
                                        return(
                                            <Carousel.Item key={index}>
                                                    <ImageCard imageData={real.src} count={index}/>
                                            </Carousel.Item>
                                        );
                                    })}
                                </Carousel> 
                </div>
        </>
    );
}


export default LogoList;