import axios from 'axios';
import { useEffect, useState} from 'react';
import TestomonialCard from './testomonialCard';
import Carousel from 'react-grid-carousel';

import {FiArrowLeft,FiArrowRight } from 'react-icons/fi';

function TestomonialList({onSetPop, currentTestInfo}){
    const url = `https://acgstu.com/acg/v1/contact/${process.env.REACT_APP_ADMIN_ID}/feedback`;
    const [feedback, setFeedback] = useState();
    const [isLeftHover, setIsLeftHover] = useState(false);
    const [isRightHover, setIsRightHover] = useState(false);
    const [screenSize, setScreenSize] = useState(getCurrentDimensions());
    const [allowPop, setAllowPop] = useState(false);
    const [testomonial, setTestomonial] = useState("");

    function getCurrentDimensions(){
        return{
            width: window.innerWidth,
            height: window.innerHeight,
        }
    }

    useEffect(() => {
        callForFeedbacks();
        if(allowPop){
            currentTestInfo(testomonial);
            onSetPop(true);
        }
        const updateDimension = () => {
            setScreenSize(getCurrentDimensions());
        }
        window.addEventListener('resize', updateDimension);

        return(() => {
            window.removeEventListener('resize', updateDimension)
        })

    }, [screenSize, allowPop])



    const callForFeedbacks = async () => {
        await axios.get(url).then((response) => {
            setFeedback(response.data.feedback);
        })
    };

    const leftArrow = () => {
        return(
            <div className='testArrowHolder'>
                <div className='testArrowLeft' onMouseEnter={() => setIsLeftHover(true)} onMouseLeave={() => setIsLeftHover(false)}>
                    <FiArrowLeft size={60} color={[isLeftHover ? "#3eacd1" : "#562873"]} />
                </div>
            </div>
        );
    }
    const rightArrow = () => {
        return(
            <div className='testArrowHolder'>
                <div className='testArrowRight' onMouseEnter={() => setIsRightHover(true)} onMouseLeave={() => setIsRightHover(false)}>
                    <FiArrowRight size={60} color={[isRightHover ? "#3eacd1" : "#562873"]} />
                </div>
            </div>
        );
    }
    const MyDot = ({ isActive }) => (
        <span
          style={{
            display: 'inline-block',
            height: isActive ? '20px' : '10px',
            width: isActive ? '20px' : '10px',
            background: isActive ? '#52D477' : "#562873",
            borderRadius: "35px"
          }}
        ></span>
      )
    return(
        <>
            {feedback != null ? (
                <div className='testMain'>
                    <Carousel autoplay={5000} cols={[screenSize.width <= 1525 ? 1 : 3]} rows={1} gap={100} loop showDots={true} dot={MyDot} arrowLeft={leftArrow} arrowRight={rightArrow}>
                        {feedback.map((item, index) => {
                            return(
                                <Carousel.Item key={index}>
                                <TestomonialCard giveMeMyTestomonial={setTestomonial} testomonial={item} onAllowPop={setAllowPop} />
                                </Carousel.Item>
                            );
                        })}
                        </Carousel>
                </div>
            ):null}
        </>
    );
}


export default TestomonialList;