//import all images 
import acg from "../assets/gallery/acg.png";
import aclass from "../assets/gallery/aclass.png";
import bic from "../assets/gallery/bic.png";
import herm from "../assets/gallery/herm.png";
import hf from "../assets/gallery/hf.png";
import hue from "../assets/gallery/hue.png";
import jad from "../assets/gallery/jad.png";
import lkf from "../assets/gallery/lkf.png";
import mo from "../assets/gallery/mo.png";
import ntuns from "../assets/gallery/ntuns.png";
import polytic from "../assets/gallery/polytic.png";
import pp from "../assets/gallery/pp.png";
import senty from "../assets/gallery/senty.png";
import tap from "../assets/gallery/tap.png";
import tl from "../assets/gallery/tl.png";
import uufl from "../assets/gallery/uufl.png";
import uuflb from "../assets/gallery/uuflb.png";
import you from "../assets/gallery/youtube.png";
import zl from "../assets/gallery/zl.png";

export const imageList = [
        {
            id:1,
            src: acg,
            alt: "ACG Leaderboards"
        },
        {
            id:2,
            src: aclass,
            alt: "A Classic Gamer"
        },
        {
            id:3,
            src: bic,
            alt: "Stay Lit NFT"
        },
        {
            id:4,
            src: herm,
            alt: "Sentypede collector card"
        },
        {
            id:5,
            src: hf,
            alt: "Happy Face Logo"
        },
        {
            id:6,
            src: hue,
            alt: "Hue Dating App Logo"
        },
        {
            id:7,
            src: jad,
            alt: "Just A Dab Logo"
        },
        {
            id:8,
            src: lkf,
            alt: "LKF Youtube"
        },
        {
            id:9,
            src: mo,
            alt: "Sentypede collector card"
        },
        {
            id:10,
            src: ntuns,
            alt: "Nicole Tax Services logo"
        },
        {
            id:11,
            src: polytic,
            alt: "Social Media Just Platform"
        },
        {
            id:12,
            src: pp,
            alt: "Pro Printz Logo"
        },
        {
            id:13,
            src: senty,
            alt: "Sentypede Mobile Game"
        },
        {
            id:14,
            src: tap,
            alt: "Tapeazy Logo"
        },
        {
            id:15,
            src: tl,
            alt: "Trevor Logo"
        },
        {
            id:16,
            src: uufl,
            alt: "Uniquely U Fashions"
        },
        {
            id:17,
            src: uuflb,
            alt: "Uniquely U Alternative"
        },
        {
            id:18,
            src: you,
            alt: "Random IT Guy Logo"
        },
        {
            id:19,
            src: zl,
            alt: "Zahara Band"
        }
];