import "./Modal.css";
import errorImage from "../assets/404.png";
import { Button } from "./Button"

export const ErrorModal = ({ closeModal }) => {
  return (
    <div className="errorBackground">
      <div className="errorContainer">
        <div className="modalTitle">
            <img className="errorImage" src={errorImage} alt="404 error message image"/>
        </div>
        <div className="errorBody">
            <h2>
                Error 404 - Unable to Locate this Page
            </h2>
            <p>
                The page you are looking for may have been removed<br/>
                either it has had its name changed or it is temporarily unavailable.
            </p>
            <h4>Sorry for the inconvenience</h4>
        </div>
        <div className="footerModal">
        <Button
            className="myErrorButton"
            linkTitle="Return Home"
            location="/"
            buttonStyle="btn--pill"
            buttonSize="btn--medium"
          />
        </div>
      </div>
    </div>
  );
};
