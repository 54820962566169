import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";

import { Helmet } from "react-helmet";

import * as Logo from "../assets/acgFinal.json";

import { MdCleaningServices } from "react-icons/md";
import { FaDollarSign } from "react-icons/fa";
import { HiDesktopComputer } from "react-icons/hi";
import { AiOutlineUser } from "react-icons/ai";
import { FinalRating } from "../components/FinalRating";

import { CgWebsite } from "react-icons/cg";
import { MdAppShortcut, MdOutlineDevicesOther } from "react-icons/md";

//Custom Components
import { Button } from "../components/Button";
import { Card } from "../components/Card";
import "./Home.css";
import TestomonialList from "../components/testomonialList";

function Home() {

  const [canPop, setCanPop] = useState(false);
  const [allowTest, setAllowTest] = useState();
  useEffect(() => {
    if(canPop){
      console.log(allowTest)
    }
  }, [canPop])
  return (
    <div className="homeContainer">
    <Helmet>
        <title>
          ACG Studios Home
        </title>
        <meta name='description' content="Home for all for your IT needs. From web design, web development, logo design, mobile development, game development, network configuration, and pc repairs" />
        <meta name='keywords' content="web development, web design, logo design,mobile, game development, network configuration, and pc repairs" />
    </Helmet>
    {canPop ? (
      <div className="reviewPopUpHolder">
        <div className="reviewContents">
        <div className="testHeaderHolder">
                <AiOutlineUser 
                style={{
                    color:"black",
                    fontSize: "88px"
                }}
                />
                <div className="testInfoTitle">
                    <h3>{allowTest.name}</h3>
                    <h5>{allowTest.service}</h5>
                    <FinalRating
                        rating={allowTest.rating} 
                        name="finalRating"
                        type="radio"
                        inputClass="finalStar"
                    />
                    
                </div>
                
                </div>
                <hr className="line"/>
                <p>{allowTest.description}</p>
                <button onClick={() => setCanPop(false)} className="btn--medium testomonial">Close Testomonial</button>
        </div>
      </div>
    ):(
      <>
        <div className="homeData">
          <div className="homeAnimation">
            <Lottie className="lottieAnim" loop={false} animationData={Logo} />
          </div>
          <div className="homeInfo">
            <div className="">
              <h1>Welcome to ACG Studios Hub</h1>
              <p>
                Home for all your IT related needs,
                <br /> from computer repair, web development, webhosting,
                <br />
                app development, game development, and so much more <br /> contact
                us now and we will get you squared away..
              </p>
            </div>
            <div className="homeBtnContainer">
              <Button location="/about" linkTitle="About Us" buttonSize={"btn--medium"} buttonStyle={"btn--full"} />

              <Button location="/contact" linkTitle="Contact Us" buttonSize={"btn--medium"} buttonStyle={"btn--full"} />
            </div>
          </div>
        </div>
        <div className="homeService">
          <h1>
            <MdCleaningServices /> Services We Provide
          </h1>
          <div className="fee">
            <h4>For All Consultations </h4>
            <h4>
              Our Starting fee is <FaDollarSign /> 10.99
            </h4>
          </div>
          <div className="cardHolder">
            <Card
              icon={<HiDesktopComputer />}
              title="Computer Repair"
              info="Do you have a computer running sluggish or just won't turn on? 
            Look no further we got you! Let the ACG Studios team help you out with this."
              backgroundColor="card--first"
            />
            <Card
              icon={<CgWebsite />}
              title="Webhosting/Web Development"
              info="Need Web hosting, or someone to build you your custom site. 
              Our quality designs are responsive and will work across all platforms. 
              Get your site built today"
              backgroundColor="card--second"
            />
            <Card
              icon={<MdAppShortcut />}
              title="App Development & Design"
              info="Are you in need of app services, do you have an app idea or need help publishing your app?
              We know that can be a daunting task, let us illiminate that pain. 
              We help you from development all the way to live releases."
              backgroundColor="card--third"
            />
            <Card
              icon={<MdOutlineDevicesOther />}
              title="Other Services"
              info="Are you looking for something more simple, we also build PCs, smart mirrors, arcade machines, 
              or do you need your system more customized. 
              Let us know and we can help you out"
              backgroundColor="card--fourth"
            />
          </div>
          <div className="serviceBtn">
            <Button location="/contact" linkTitle="Schedule Consultation" buttonStyle="btn--pill" buttonSize="btn--medium" />
          </div>
        </div>
        <div className="projectsSection">
          <h2>ACG Studios Projects</h2>
          <div className="projectContainerOne">
            <div className="project projectOne">
              <p>
                We have several projects on the way. Majority of our apps are
                designed to meet the needs of the people and we have several ideas
                to help meet those needs, even if you don't know you need it yet.
                One of our projects is called e-grams, and we will display content
                and proof of concept soon. So stay tuned
              </p>
            </div>
            <div className="project projectTwo">
              <p>
                At ACG Studios we have put a few apps out there, but due to
                sickness and covid we werent able to maintain those applications
                anymore, so we are starting fresh, with new concepts. Our next
                project will be released soon and we look forward to the support
                of your downloads and reviews.
              </p>
            </div>
          </div>
          <div className="projectContainerTwo">
            <div className="project projectThree">
              <p>
                Not only are we doing mobile applications and games, but we also
                build software for the pc and mac. We are working on a ticketing
                system that we look forward to releasing soon. There are alot of
                features that will be integrated with the ticketing system. From
                Active Directory, and email integration right into the software.
                Not only will it be a ticketing system it will also have inventory
                system.
              </p>
            </div>
          </div>
          <div className="testomonialSection">
            <div className="testomonialData">
            <div className="testHeaders">
              <h2>Testomonials</h2>
              <h4>See what our clients have to say</h4>
              </div>

            <div className="testList">
              <TestomonialList onSetPop={setCanPop} currentTestInfo={setAllowTest}/>
            </div>
            </div>
          </div>
        </div> 
      </>     
    )}
    </div>
  );
}

export default Home;
