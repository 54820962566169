import React, { useState } from "react";
import { Form, Formik } from "formik";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
//Inputs
import { Input } from "./Inputs/Input";
import { DropDown } from "./Inputs/DropDown";
import { TextArea } from "./Inputs/TextArea";

//Validation from yup
import { basicSchema } from "../schemas";

const onSubmit = async (values, actions) => {
  const url = "https://acgstu.com/acg/v1/contact/create-contact";
  if (values.email === "" || values.name === "" || values.description === "" || values.service === "notRight") {
    alert("You Must fill Out all required fields in order to proceed");
  } else {
    // We will send via axios the values and send email through node js
    await axios.post(url, values).then((res) => {
      if (res.status === 201 || res.status === 200) {
        alert(
          "Thank You for submitting your contact information, Somebody will be with you shortly"
        );
      } else {
        alert("Something went wrong, please try again later");
      }
    });
  }
  await new Promise((resolve) => setTimeout(resolve, 1000));
  actions.resetForm();
};

export const ContactForm = () => {
  const [success, setSuccess] = useState(false);

  const successHandler = () => {
    setSuccess(true);
  };

  const expiredHandler = () => {
    setSuccess(false);
  };

  const errorHandler = () => {
    setSuccess(false);
    alert("Please Check Your Internet Connection");
  };

  return (
    <Formik
      initialValues={{ name: "", email: "", description: "", phone: "", service: "" }}
      validationSchema={basicSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <Input
          inputClass="contactInput"
          errorClass="errorInput"
          label="Name:"
          name="name"
          type="text"
          placeholder="Enter Your Name..."
        />
        <Input
          inputClass="contactInput"
          errorClass="errorInput"
          label="Email:"
          name="email"
          type="email"
          placeholder="Enter Your Email Address..."
        />
        <Input
          inputClass="contactInput"
          errorClass="errorInput"
          label="Phone Number:"
          name="phone"
          type="phone"
          placeholder="9992993999"
        />
        <DropDown selectClass="contactDrop" name="service">
          <option value="notRight">Please select your service...</option>
          <option value="pc-repair">Computer Repair</option>
          <option value="website">Webhosting/Website</option>
          <option value="app">App Idea</option>
          <option value="game">Game Idea</option>
        </DropDown>
        <TextArea labelInfo="Details about your needs" textClass="contactDescription" name="description" />
        <div className="recaptchaHolder">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_SITE_KEY}
            onChange={successHandler}
            onExpired={expiredHandler}
            onErrored={errorHandler}
          />
        </div>
        <div className="contactfooterModal">
          {!success ? (
            <div className="robot">
              <p>Must confirm that you are not a robot...</p>
            </div>
          ) : (
            <button className="contactBtn" type="submit">
              Submit
            </button>
          )}
        </div>
      </Form>
    </Formik>
  );
};
