import { Helmet } from "react-helmet";
import "./AboutUs.css"
function AboutUs (){
    return(
        <div className="aboutBackground">
            <Helmet>
            <title>
                About Us
            </title>
                <meta name='description' content="Learn about the background of ACG Studios, and how we came to be as a company." />
            </Helmet>
            <div className="aboutContainer">
                <div className="aboutTitle">
                    <h1>About Us</h1>
                </div>
                <div className="aboutBody">
                    <h3>Get to Know Us</h3>
                    <p>ACG Studios started from a very simple idea. 
                        We wanted to create fun, retro style games, restore and redevelop classic arcade systems. 
                        As time grew the idea grew as we learned more and more in the tech industry from simple help desk all the way to System Administration, and automation scripting for servers, too manipulating old outdated software. 
                        That’s when the passion grew for ACG Studios. We wanted to build games and software for others. 
                        While maintaining our own internal development.
                        So, that’s when the plan came together. 
                        Why stop at just mobile app development and game development. 
                        Let’s branch out into all forms of development that matter to the world. 
                        We want to help you create your dream application, rather that be for gaming, mobile app, website, or just a simple gift for a family member or friend in the form of arcade machines or smart mirrors. 
                        We want to help you make your dreams a reality.
                    </p>
                </div>
            </div>
            
        </div>
    );
}

export default AboutUs;