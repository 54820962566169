import { FinalRating } from "../components/FinalRating";
import { AiOutlineUser } from "react-icons/ai";
import './testomonial.css'
import { useEffect, useState } from "react";

function TestomonialCard ({testomonial, onAllowPop, giveMeMyTestomonial}) {

    const [desLimiter, setDesLimiter] = useState(0);
    const [testDescription, setTestDescription] = useState("");
    const [popUp, setPopUp] = useState(false);

    useEffect (() => {
        const descriptionWords = testomonial.description;

        const finalWordCount = descriptionWords.split(" ").length;

        if(finalWordCount >= 82){
            setDesLimiter(finalWordCount);
            const myDescriptionCut = cutOffWords(descriptionWords, 75);
            setTestDescription(myDescriptionCut);
        }
    }, [])

    const cutOffWords = (str, count) => {
        return str.split(" ").splice(0, count).join(" ");
    }

    const popUpModal = () => {
        onAllowPop(true);
        giveMeMyTestomonial(testomonial);
    }

    return(
            <div className="testCardHolder" >
                <div className="testHeaderHolder">
                <AiOutlineUser 
                style={{
                    color:"black",
                    fontSize: "88px"
                }}
                />
                <div className="testInfoTitle">
                    <h3>{testomonial.name}</h3>
                    <h5>{testomonial.service}</h5>
                    <FinalRating
                        rating={testomonial.rating} 
                        name="finalRating"
                        type="radio"
                        inputClass="finalStar"
                    />
                    
                </div>
                
                </div>
                <hr className="line"/>
                {desLimiter >= 82 ? (
                    <>
                    <p>{testDescription}</p>
                    <button onClick={() => popUpModal()} className="btn--medium testomonial" type="button">Read More</button>
                    </>
                ):(
                    <p>{testomonial.description}</p>
                )}
            </div>     
    );
}

export default TestomonialCard;