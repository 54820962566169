import { Form, Formik } from "formik";
import axios from "axios";

//Inputs
import { Input } from "./Inputs/Input";
//Validation from yup
import { footerSchema } from "../schemas/footerSchema";

const onSubmit = async (values, actions) => {
  const url = "https://acgstu.com/acg/v1/contact/create-news";
  if (values.email === "") {
    alert("Must have a valid email to submit");
  } else {
    await axios.post(url, values).then((res) => {
      if (res.status === 201 || res.status === 200) {
        alert(
          "Thank You, for signing up to our newsletter, stay tuned for some new features and job postings"
        );
      } else {
        alert("Something went wrong, please try again later");
      }
    });
  }
  await new Promise((resolve) => setTimeout(resolve, 1000));
  actions.resetForm();
};

export const NewsLetterForm = () => {
  return (
    <Formik
      initialValues={{ email: "" }}
      validationSchema={footerSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <div className="footerTextInputs">
          <Input
            labelColor="footerLabel"
            placeholder="Enter Your Email Address..."
            errorClass="footerError"
            inputClass="footerInput"
            name="email"
            type="email"
            label="Sign up for Exclusive Deals and Tech News"
          />
          <button type="submit">Submit</button>
        </div>
      </Form>
    </Formik>
  );
};
