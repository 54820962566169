import { FaStar } from "react-icons/fa";
import { useField } from "formik";

import "./Input.css";
import { useState } from "react";

export const Rating = ({
    label,
    labelColor,
    errorClass,
    inputClass,
    onClick,
    myRating,
    colorSettings,
    onMouseEnter,
    onMouseLeave,
    currentRating,
    disabled,
    ...props
}) => {
    const [field, meta] = useField(props);
    const [rating, setRating] = useState(null);
    const [hover, setHover] = useState(null);
    return(
        <div className="starHolder">
            <label>{label}</label>
            <div className="starHandler">
        {[...Array(5)].map((star, i) => {
            const ratingValue = i + 1;
            return(
                <label>
                    <input
                        {...field}
                        {...props}
                        value={ratingValue}
                        onClick={() => setRating(ratingValue)}

                        className={meta.touched && meta.error ? errorClass : inputClass} 
                    />
                    <FaStar 
                        size={45} 
                        className="starButton"
                        color={ratingValue <= (hover || rating) ? "#ffc107" : "#180A4E" }
                        onMouseEnter={() => setHover(ratingValue)}
                        onMouseLeave={() => setHover(null)}
                    />
                </label>
            );
        })}
        </div>
        </div>
    );
}

