import React from "react";
import { Helmet } from "react-helmet";
import "./ContactUs.css";
import { FeedBackForm } from "../components/FeedBackForm";

export const Feedback = () => {

  return (
    <div className="contactBackground">
      <Helmet>
      <title>
          ACG Leave Testomonial
        </title>
        <meta name='description' content="Tell us how we did, and how we helped your team out. We greatly appreciate the feed back." />
      </Helmet>
      <div className="contactContainer">
        <div className="contactTitle">
          <h2>Leave a Testomonial</h2>
          <h4>Let us Know How we Did</h4>
        </div>
        <div className="contactBody">
          <FeedBackForm />
        </div>
      </div>
    </div>
  );
};

export default Feedback;