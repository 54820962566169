import "./Services.css";

import { Button } from "../components/Button";

//Icons
import { MdCleaningServices } from "react-icons/md";
import { HiDesktopComputer } from "react-icons/hi";
import { CgWebsite } from "react-icons/cg";
import { FaDollarSign } from "react-icons/fa";
import { MdAppShortcut, MdOutlineDevicesOther } from "react-icons/md";
import { Card } from "../components/Card";
import { Helmet } from "react-helmet";

function Services() {
  return (
    <>
      <div className="serviceBackground">
        <Helmet>
        <title>
          ACG Services
        </title>
        <meta name='description' content="Learn about our services and get your quote today." />
        </Helmet>
        <div className="serviceContainer">
          <div className="serviceTitle">
            <h1>
              <MdCleaningServices /> Services We Provide
            </h1>
            <div className="fee">
              <h4>For All Consultations </h4>
              <h4>
                Our Starting fee is <FaDollarSign /> 10.99
              </h4>
            </div>
          </div>
          <div className="serviceBody">
            <div className="serviceCardsContainer">
              <Card
                myClass="servicePage"
                icon={<HiDesktopComputer />}
                title="Computer Repair"
                info="Do you have a computer running sluggish or just won't turn on? 
          Look no further we got you! Let the ACG Studios team help you out with this."
                backgroundColor="card--first"
              />
              <Card
                myClass="servicePage"
                icon={<CgWebsite />}
                title="Webhosting/Web Development"
                info="Need Web hosting, or someone to build you your custom site. Our quality designs are responsive and will work across all platforms. Get your site built today"
                backgroundColor="card--second"
              />
              <Card
                myClass="servicePage"
                icon={<MdAppShortcut />}
                title="App Development & Design"
                info="Are you need of app services, do you have an app idea and need help publishing your app?
          ACG Studios can help with all app needs. From development all the way to live releases."
                backgroundColor="card--third"
              />
              <Card
                myClass="servicePage"
                icon={<MdOutlineDevicesOther />}
                title="Other Services"
                info="Not sure what you need? Well talk to us and we might be able to point you in the right direction"
                backgroundColor="card--fourth"
              />
            </div>
          </div>
          <div className="serviceButtonContainer">
          <Button
            linkTitle="Schedule Your Consultation"
            location="/contact"
            buttonStyle="btn--pill"
            buttonSize="btn--medium"
          />
          </div>
        </div>
      </div>
    </>
  );
}

export default Services;
