import React, { useState} from "react";
import { Link } from "react-router-dom";
import ACGLogo from "../assets/acgLogo.png";

import "./Navbar.css";

function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <>
      <nav className="navbar">
        <div className="navContainer">
          <Link to="/" className="navLogo">
            <img
              className="navLogoImage"
              src={ACGLogo}
              alt="ACG Studios Logo"
            />
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <Link to="/" className="nav-Links" onClick={closeMobileMenu}>
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/services"
                className="nav-Links"
                onClick={closeMobileMenu}
              >
                Services
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/products"
                className="nav-Links"
                onClick={closeMobileMenu}
              >
                Products
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/gallery"
                className="nav-Links"
                onClick={closeMobileMenu}
              >
                Gallery
              </Link>
            </li>              
            <li className="nav-item">
              <Link
                to="/contact"
                className="nav-Links"
                onClick={closeMobileMenu}
              >
                Contact Us
              </Link>
            </li>          
          </ul>
          <Link to="/" className="navLogo2">
            <img
              className="navLogoImage"
              src={ACGLogo}
              alt="ACG Studios Logo"
            />
          </Link>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
