import "./Input.css";
import { useField } from "formik";
export const Input = ({
  label,
  labelColor,
  errorClass,
  inputClass,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <div className="input">
      <label className={labelColor}>{label}</label>
      <input
        {...field}
        {...props}
        className={meta.touched && meta.error ? errorClass : inputClass}
      />
      {meta.touched && meta.error && (
        <div className="messageError">
          <p>{meta.error}</p>
        </div>
      )}
    </div>
  );
};
