import { Helmet } from "react-helmet";
import "./Product.css";
function Products() {
  return (
    <div className="productBackground">
      <Helmet>
      <title>
          ACG Products
        </title>
        <meta name='description' content="ACG Studios has some pretty cool merch. We will keep you in style with some of our signature items" />
      </Helmet>
      <div className="productContainer">
        <div className="productTitle">
          <h2>Products</h2>
        </div>
        <div className="productBody">
          <h4>Sorry for the inconvenience</h4>
          <p>
            We currently don't have any products to provide you at this time
          </p>
          <p>
            Sign up for our newsletter and we will keep up to date with future
            products and more...
          </p>
        </div>
      </div>
    </div>
  );
}

export default Products;
