import "./TextArea.css";
import { useField } from "formik";

export const TextArea = ({ textClass, labelInfo, ...props  }) => {
  const [field, meta] = useField(props);

  return (
    <div className="textArea">
      <label>{labelInfo}</label>
      <textarea
        {...field}
        {...props}
        className={meta.touched && meta.error ? "errorDescription" : textClass}
      />
      {meta.touched && meta.error && (
        <div className="errorMessage">
          <p>{meta.error}</p>
        </div>
      )}
    </div>
  );
};
