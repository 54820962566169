import { ErrorModal } from "../components/ErrorModal";

function Error(){
    return(
        <>
        <ErrorModal />
        </>
    );
}

export default Error;