import { Helmet } from "react-helmet";
import React from "react";
import HTMLFlipBook from 'react-pageflip';

import "./Magazine.css"
import Page from "../components/Page";
import PageCover from "../components/PageCover";


class Magazine extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      totalPage: 0,
    };
  }
  
  nextButtonClick = () => {
    this.flipBook.getPageFlip().flipNext();
  };

  prevButtonClick = () => {
    this.flipBook.getPageFlip().flipPrev();
  };

  onPage = (e) => {
    this.setState({
      page: e.data,
    });
  };

  componentDidMount() {
    this.setState({
      totalPage: this.flipBook.getPageFlip().getPageCount(),
    });
  }

  render(){
    return (
      <div className="magBackground">
        <Helmet>
        <title>
            ACG Magazine
          </title>
          <meta name='description' content="Keep up to date with all the cool projects and things we are working on as a team" />
        </Helmet>
        <div className="magContainer">
          <HTMLFlipBook 
            width={500} 
            height={500}
            size="stretch"
            minWidth={315}
            maxWidth={1000}
            minHeight={400}
            maxHeight={1533}
            maxShadowOpacity={0.5}
            showCover={true}
            mobileScrollSupport={true}
            onFlip={this.onPage}
            onChangeOrientation={this.onChangeOrientation}
            onChangeState={this.onChangeState}
            className="demo-book"
            ref={(el) => (this.flipBook = el)}
            >
            <PageCover>ACG Magazine</PageCover>
              <Page number={1} title="Sentypede">
              Welcome to our magazine page we currently don't have any data to provide at this time,
                but we will have our magazine up and running very soon. Sorry for the inconvenience.
              </Page>
          </HTMLFlipBook>
        </div>
      </div>
    );
  }
}

export default Magazine;
