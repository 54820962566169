import "./App.css";
import { Routes, Route} from "react-router";
import { Helmet } from "react-helmet";


//Pages
import Home from "./pages/Home";
import ContactUs from "./pages/ContactUs";
import AboutUs from "./pages/AboutUs";
import Privacy from "./pages/politics/Privacy";
import Terms from "./pages/politics/Terms";
import Careers from "./pages/Careers";
import Products from "./pages/Products";
import Services from "./pages/Services";
import Magazine from "./pages/Magazine";
import Feedback from "./pages/Feedback";
import Gallery from "./pages/Gallery";
import Error from "./pages/Error";


function App() {
  return (
    <>
    <Helmet>
        <title>
          ACG Studios Home
        </title>
        <meta name='description' content="Home for all for your IT needs. From web design, web development, logo design, mobile development, game development, network configuration, and pc repairs" />
        <meta name='keywords' content="web development, web design, logo design,mobile, game development, network configuration, and pc repairs" />
    </Helmet>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contact" element={<ContactUs />} exact />
      <Route path="/about" element={<AboutUs />} exact />
      <Route path="/products" element={<Products />} exact />
      <Route path="/acgmagazine" element={<Magazine />} exact />
      <Route path="/careers" element={<Careers />} exact />
      <Route path="/gallery" element={<Gallery/>} exact />
      <Route path="/services" element={<Services />} exact />
      <Route path="/terms" element={<Terms />} exact />
      <Route path="/privacy" element={<Privacy />} exact />
      <Route path="/feedback" element={<Feedback />} exact />
      <Route path="*" element={<Error />} exact />
    </Routes>
    </>
  );
}

export default App;
