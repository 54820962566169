import {useEffect, useState} from "react";
import { Helmet } from "react-helmet";
import "./Gallery.css";
import LogoList from "../components/logoList";
import { imageList } from "../helpers/imageGatherer";



function Gallery(){

    const [screenSize, setScreenSize] = useState(getCurrentDimensions());

    function getCurrentDimensions(){
        return{
            width: window.innerWidth,
            height: window.innerHeight,
        }
    }

    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimensions());
        }
        window.addEventListener('resize', updateDimension);
        const handleContextMenu = (e) => {
            // prevent the right-click menu from appearing
            e.preventDefault()
          }
      
          // attach the event listener to 
          // the document object
          document.addEventListener("contextmenu", handleContextMenu)
      
          // clean up the event listener when 
          // the component unmounts
          return () => {
            document.removeEventListener("contextmenu", handleContextMenu)
            window.removeEventListener('resize', updateDimension)
          }
            // define a custom handler function
    // for the contextmenu event

    }, [screenSize])

    return(
        <div className="galleryMain">
            <Helmet>
            <title>
                ACG Gallery
                </title>
                <meta name='description' content="Check out some of our work. We have several art designs that we have done over the years." />
            </Helmet>
            <h2>Some of Our Work</h2>
            <div>
                {screenSize.width > 575 ? (
                    <>
                    <div className="imageContainer">
                        {imageList.map((image, index) => {
                            return(
                            <div key={index} className="imageHolder">
                                <img src={image.src} alt={image.alt} />
                            </div>
                            );
                        })}
                        </div>
                    </>
                ): (
                    <div className="galleryHolder">
                        <h3>Gallery Previewer</h3>
                        <LogoList />
                    </div>
                )}
            </div>
        </div>
    );
}

export default Gallery;