import { FaStar } from "react-icons/fa";

export const FinalRating = ({
    rating
}) => {

    return(
        <div className="finalRateHolder">
            <div className="starHandler">
        {[...Array(rating)].map((index) => {
            return(
                 <>
                    <FaStar
                        key={index} 
                        size={30} 
                        className="starButton"
                        color="#ffc107"
                    />
                </>
            );
        })}
        </div>
        </div>
    );
}

