import React from "react";

import "./ContactUs.css";
import { ContactForm } from "../components/ContactForm";
import { Helmet } from "react-helmet";

export const ContactUs = () => {

  return (
    <div className="contactBackground">
      <Helmet>
      <title>
          Contact Us
        </title>
        <meta name='description' content="This is one of the easiest ways to get in contact with us. Leave us your information and someone will be with you shortly. " />
      </Helmet>
      <div className="contactContainer">
        <div className="contactTitle">
          <h2>How Can We Help You?</h2>
          <h4>Contact Information</h4>
        </div>
        <div className="contactBody">
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
