import React from "react";

import "./Card.css"



export const Card = ({  
    icon,
    myClass,
    title,
    info,
    backgroundColor
}) => {

        return(
            <div className={`card ${myClass} ${backgroundColor}`}>
                <h2>{icon}{title}</h2>
                <p>{info}</p>
            </div>
        );

}