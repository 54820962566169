import * as yup from "yup";

const phoneRegExp = "^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$";
const nameReg = "([A-Z][a-z]*)([\\s\\'-][A-Z][a-z]*)*";
export const basicSchema = yup.object().shape({
  name: yup
    .string()
    .min(2, "Must have more than one letter")
    .matches(nameReg, "Please enter valid name")
    .max(50)
    .required(),
  email: yup.string().email("Please Enter a Valid Email").required(),
  phone: yup
    .string()
    .min(10, "Must Have area code and full number")
    .matches(phoneRegExp, "Phone number is not valid"),
  service: yup
    .string()
    .oneOf(["pc-repair", "website", "app", "game"], "Please select one")
    .required(),
  description: yup
    .string()
    .required("Please give us some details about your request"),
});
