import { Helmet } from "react-helmet";
import "./Careers.css";
function Careers() {
  return (
    <div className="careerBackground">
      <Helmet>
      <title>
          ACG Careers
        </title>
        <meta name='description' content="Looking for a new opportunity. ACG Studios is working to build a great development community." />
      </Helmet>
      <div className="careerContainer">
        <div className="careerTitle">
          <h2>Careers</h2>
        </div>
        <div className="careerBody">
          <h4>Sorry for the inconvenience</h4>
          <p>We currently don't have any careers available at this time</p>
          <p>
            We will keep you guys posted when we do have something available
          </p>
          <p>Thank you for your interest in working with ACG Studios</p>
        </div>
      </div>
    </div>
  );
}

export default Careers;
