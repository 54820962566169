import React from "react";

import { Link } from "react-router-dom";
import ACGLogo from "../assets/acgLogo.png";

import { FaLinkedin, FaInstagram, FaTwitterSquare } from "react-icons/fa";

import "./Footer.css";
import { NewsLetterForm } from "./NewsletterForm";

function Footer() {
  return (
    <div className="footer">
      <div className="footerContainer">
        <div className="footImageContainer footFirstImage">
          <Link to="/" className="footNavLogo">
            <img
              className="navLogoImage"
              src={ACGLogo}
              alt="ACG Studios Logo"
            />
          </Link>
        </div>
        <div className="listContainer">
          <div className="list footListOne">
            <h3>Customer Care</h3>
            <ul>
              <li>
                <Link to="/contact" className="footLinks">
                  Contact Us
                </Link>
              </li>
              <li>
                <Link to="/feedback" className="footLinks">
                  Write Testomonial
                </Link>
              </li>
              <li>
                <Link to="/terms" className="footLinks">
                  Terms of Service
                </Link>
              </li>
              <li>
                <Link to="/privacy" className="footLinks">
                  Privacy
                </Link>
              </li>
            </ul>
          </div>
          <div className="list footListTwo">
            <h3>Get To Know Us</h3>
            <ul>
              <li>
                <Link to="/about" className="footLinks">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/" className="footLinks">
                  Blog Coming Soon
                </Link>
              </li>
              <li>
                <Link to="/acgmagazine" className="footLinks">
                  ACG Magazine
                </Link>
              </li>
              <li>
                <Link to="/careers" className="footLinks">
                  Careers
                </Link>
              </li>
            </ul>
          </div>
          <div className="list footListThree">
            <h3>Socials</h3>
            <ul>
              <li>
                <a
                  href="https://www.linkedin.com/company/acg-studios/"
                  className="footLinks"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin />
                </a>
              </li>
              <li>
                <a
                  href="https://www.twitter.com/a_classic_gamer"
                  className="footLinks"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTwitterSquare />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/acg.studios/"
                  className="footLinks"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footImageContainer lastFootImage">
          <Link to="/" className="footNavLogoTwo">
            <img
              className="navLogoImage"
              src={ACGLogo}
              alt="ACG Studios Logo"
            />
          </Link>
        </div>
      </div>
      <div className="finalFooterComponents">
        <div className="footerSignUpContainer">
          <div>
            <NewsLetterForm />
          </div>
        </div>
        <h3>&copy; ACG Studios LLC.</h3>
      </div>
    </div>
  );
}

export default Footer;
