
import './imageCard.css'
function ImageCard ({imageData, count}){
    return(
        <div className="imageCardContainer">
            <div className="imageCard">
                <img src={imageData} alt={`ACG-${count}`} />
            </div>
        </div>
    );
}

export default ImageCard;