import * as yup from "yup";

export const TestomonialSchema = yup.object().shape({
  email: yup.string().email("Please Enter a Valid Email"),
  rating: yup.number()
  .min(1, "Must at least be one or above")
  .max(5, "Can't Go Beyond the maximum amount"),
  service: yup.string()
  .oneOf(["pc-repair", "website", "app", "game", "other"], "Please select one")
  .required(),
  description: yup
  .string()
  .required("Please give us some details about your request"),
});
