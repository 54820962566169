import "./DropDown.css";
import { useField } from "formik";

export const DropDown = ({selectClass, ...props}) => {

  const [field, meta] = useField(props);
  
  return (
    <div className="dropDown">
      <label>Choose Service:</label>
      <select {...field} {...props} className={meta.touched && meta.error ? "errorSelection" : selectClass} />
      {meta.touched && meta.error && <div className="errorMessage"><p>{meta.error}</p></div>}
    </div>
  );
};
